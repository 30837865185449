.dashboard_info{
    /* padding: 2em 2em; */
    padding-left: 40px;
    padding-top: 10px;
    /* height: 100vh;
    top: 7vw; */
    position: relative;
}
/* .dashboard_info .row {
    padding: 20px;
} */
.col-md-8{
    /* margin-left: 18px; */
    width: 80%;
}
@media screen and (max-width:768px) {
    .dashboard_info{
        padding-left: 20px;
    }
}