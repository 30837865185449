.tab-content {
    background: #eee;
    padding: 30px;
}
.submitSchedule{
    margin-left:60px
}
.schedularForm .row{
    padding: 10px;
}
.ant-picker-ok {
    display: none !important;
}

@media screen and (max-width:768px) {
    .tab-content {
        padding: 4vw;
    }
    .submitSchedule{
        margin-left:auto;
        margin:auto;
        width: 100%;
    }
}