.search-result-categories > li > a {
  color: #b6b6b6;
  font-weight: 400;
}

.search-result-categories > li > a:hover {
  background-color: #ddd;
  color: #555;
}

.search-result-categories > li > a > .glyphicon {
  margin-right: 5px;
}

.search-result-categories > li > a > .badge {
  float: right;
}

.search-results-count {
  margin-top: 10px;
}

.search-result-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.search-result-item:after,
.search-result-item:before {
  content: " ";
  display: table;
}

.search-result-item:after {
  clear: both;
}

.search-result-item .image-link {
  display: block;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media (min-width: 768px) {
  .search-result-item .image-link {
    display: inline-block;
    margin: -20px 0 -20px -20px;
    float: left;
    width: 113px;
  }
}

@media (max-width: 767px) {
  .search-result-item .image-link {
    max-height: 113px;
  }
}

.search-result-item .image {
  max-width: 100%;
}

.search-result-item .info {
  margin-top: 2px;
  font-size: 12px;
  color: #999;
}

.search-result-item .description {
  font-size: 13px;
}

.search-result-item + .search-result-item {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .search-result-item-body {
    margin-left: 200px;
  }
}

.search-result-item-heading {
  font-weight: 400;
}

.search-result-item-heading > a {
  color: #555;
}

@media (min-width: 768px) {
  .search-result-item-heading {
    margin: 0;
  }
}

/* .dashboard_info .row {
                padding: 20px;
            } */

.rowstyle {
  padding: 5px !important;
}

select {
  width: 200px;
}

.multiSelectContainer input {
  margin: 10px;
}
.ant-col{
    /* padding: 14px; */
}
span.header2-text-label {
    padding: 14px;
}
