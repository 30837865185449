/*start netquix*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');

/* #Primary
================================================== */

body{
    font-family: 'Poppins', sans-serif;
	color: #212112;
	width: 100%;

}
::selection {
	color: #fff;
	background-color: #00319a;
}
::-moz-selection {
	color: #fff;
	background-color: #00319a;
}


/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 20px 0;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	padding: 10px 0;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img{
	height: 24px;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navigation-wrap{
	position: sticky;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar{
	padding: 0;
}
.navbar-brand img{
	/* height: 50px; */
	width: auto;
	display: block;

}
.navbar-toggler {
	float: right;
	border: none;
	padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
	outline: none;
}
.navbar-light .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 1px solid #000;
    transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after, 
.navbar-light .navbar-toggler-icon:before{
	width: 24px;
	position: absolute;
	height: 1px;
	background-color: #000;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
    transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after{
	top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
.nav-link{
	color: #212121 !important;
	font-weight: 500;
    transition: all 200ms linear;
}
.nav-item:hover .nav-link{
	color: #8167a9 !important;
}
.nav-item.active .nav-link{
	color: #777 !important;
}
.nav-link {
	position: relative;
	padding: 5px 0 !important;
	display: inline-block;
}
.nav-item:after{
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 2px;
	content: '';
	background-color: #8167a9;
	opacity: 0;
    transition: all 200ms linear;
}
.nav-item:hover:after{
	bottom: 0;
	opacity: 1;
}
.nav-item.active:hover:after{
	opacity: 0;
}
.nav-item{
	position: relative;
    transition: all 200ms linear;
}

/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}
.section {
    position: relative;
	width: 100%;
	display: block;
}
.full-height {
    height: 100vh;
}
.over-hide {
    overflow: hidden;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
  margin-top: 40px;
	transform: translateY(-50%);
	z-index: 20;
}
h1{
	font-size: 48px;
	line-height: 1.2;
	font-weight: 700;
	color: #212112;
	text-align: center;
}
p{
	margin: 0;
	opacity: 1;
}

body.hero-anime #switch{
	opacity: 0;
	transform: translateY(40px);
    transition-delay: 1900ms;
}
.nav-item .dropdown-menu {
    transform: translate3d(0, 10px, 0);
    visibility: hidden;
    opacity: 0;
	max-height: 0;
    display: block;
	padding: 0;
	margin: 0;
    transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
	max-height: 999px;
    transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
	padding: 10px!important;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: #212121;
	background-color: #fcfaff;
	border: none;
	border-radius: 3px;
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    transition: all 200ms linear;
}
.dropdown-toggle::after {
	display: none;
}

.dropdown-item {
	padding: 3px 15px;
	color: #212121;
	border-radius: 2px;
    transition: all 200ms linear;
}
.dropdown-item:hover, 
.dropdown-item:focus {
	color: #fff;
	background-color: rgba(129,103,169,.6);
}

body.dark{
	color: #fff;
	background-color: #1f2029;
}
/* #Media
================================================== */

@media (max-width: 767px) { 
	h1{
		font-size: 38px;
	}
	.nav-item:after{
		display: none;
	}
	.nav-item::before {
		position: absolute;
		display: block;
		top: 15px;
		left: 0;
		width: 11px;
		height: 1px;
		content: "";
		border: none;
		background-color: #000;
		vertical-align: 0;
	}
	.dropdown-toggle::after {
		position: absolute;
		display: block;
		top: 10px;
		left: -23px;
		width: 1px;
		height: 11px;
		content: "";
		border: none;
		background-color: #000;
		vertical-align: 0;
		transition: all 200ms linear;
	}
	.dropdown-toggle[aria-expanded="true"]::after{
		transform: rotate(90deg);
		opacity: 0;
	}

}

div#navbarSupportedContent ul li {
    margin: 0 15px;
    align-self: center;
}
div#navbarSupportedContent ul li button {
    padding: 5px 25px;
    background-color: #002f9a;
    color: #fff;
    border-radius: 50px;
    border: none;
}
/* .row {
	margin: 0 auto;
	width: 100%;
} */
#banner{
	/*margin-top: 1110px;*/
    position: relative;
	/* ../assests/images/banner.png 
  background-image: url(./assets/images/banner.png);*/
  background-repeat: no-repeat;
  background-size: cover;
    height: 660px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
}
#banner{
   display: flex;
}

/* .login_form{
	align-self: center;
} */

	.login_input {
    padding: 20px;
    border-radius: 20px;
    background: #ededed !important;
}
.login_input h2{
	text-transform: uppercase;
}
.sign_btn.text-center button {
    width: 100%;
    background: #002392;
    margin-top: 20px;
}
.container-fluid{
	padding: 0em 4em;
}
.about_info{
	padding: 3em 4em;
    background: #fbf9f7;
}
.about_info h3{
    color: #002392;
    font-size: 50px;
    font-weight: 700;
}
.about_info p{
	text-align: left;
}
.works{
	padding: 3em 4em;
    background: #f0f0f0;
}
.works h3{
	color: #dd3d39;
	font-weight: 600;
	padding: 20px 0px;
}
.works h4{
	color: #0e3496;
	font-weight: 600;
	padding: 10px 0px;
}
.works .col-md-4{
	margin-bottom: 50px;
}
.findgame_info{
	padding: 3em 4em;
    background: #fff;
}
.howfind img{
	width: 85%;
}
.gameinfo{
	align-self: center;
}
.gameinfo h4{
color: #0e3496;
padding: 20px 0px;
font-weight: 600;
}
.gameinfo p{
	margin-bottom: 25px;
}
.coursebtn .col-md-6{
	padding-left: 0px;
}
.coursebtn a{
	background-color: #dd3d39;
	border-radius:5px ;
	padding: 15px 20px;
	color: #fff;
	font-weight: 600;
	text-decoration: none;
}
.coursebtn a img{
    width: 25px;
    height: 25px;
    margin-right: 20px;
}
.row.coursebtn h6 {
    padding: 20px 0;
    color: #002290;
    font-weight: 700;
}
footer{
	background-color: #002290;
	padding:3em 4em ;
}
footer img{
	width: 80%;
}
footer h4{
	color: #fff;
	margin-bottom: 20px;
}
footer ul{
	margin: 0px;
	padding: 0px;
}
footer ul li{
	margin: 0px;
	list-style: none;
	padding: 5px 0;
}
footer ul li a, footer ul li a:hover{
	color: #fff;
	text-decoration: none;

}
.copyright{
	background-color: #ededed;
	padding: 1em;
}
.copyright p{
	text-align: center;
	color:#002290;
}
.socialicon_info ul li {
    margin-right: 25px;
}
.socialicon_info ul li a {
    font-size: 25px;
}


/* 	Dashboard */

.dropdown-menu {
    left: 0px;
}
.dropdown-menu.show {
    left: 0px;
}
#navbarDropdownMenuLink{
	color: #002290 !important;
}
/* .dashboard_info{
	padding: 4em 5em;
} */
.sidebar a {
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: flex;
}
 
.sidebar a {
	margin-bottom: 20px;
}
.sidebar a .fa {
    border: 2px solid #000;
    padding: 14px;
    border-radius: 50px;
    font-size: 20px;
    color: #00319b;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}

ul.nav.nav-tabs {
    background: #002d97;
    padding: 15px;
    border-radius: 10px;
}
ul.nav.nav-tabs li a label{
	color: #fff !important;
	padding:15px 20px !important;
}
ul.nav.nav-tabs p {
    color: #f5b551 !important;
    margin-top: 15px;
    float: right;
    margin-left: 22%;
    font-weight: 700;
}
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #edac5a !important;
    border: none !important;
    padding: 15px;
    height: 50px;
}
.nav-tabs .nav-item:hover .nav-link{
	border: none !important;
}

.nav-tabs.nav-item:hover .nav-link{
	color: #fff !important;
}
.tab-content .col-md-3{
	padding: 10px;
}
.tab-content .col-md-3 img{
	width: 100%;
	margin-top: 20px;
	height: 150px;
}
/* .tab-content {
    background: #eee;
    padding: 10px;
} */


p.small {
    text-align: right;
}
/* span.register { */
    /* color: #007bff;
    margin-left: 10px;
    cursor: pointer;
} */
/* .login_form input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
.sign_btn.text-center button {
    width: 100%;
    background: #002392;
    margin-top: 20px;
}
#form-switch {
  display:none
}
#register-form {
  display:none
}
#form-switch:checked~#register-form {
  display:block
}
#form-switch:checked~#login-form {
  display:none
}
.ul .navbar-nav .m1-auto
{
  margin-left: auto!important;
}  
.regHead
{
	background-color:#002d97;
	color: #f0f0f0 !important;
	padding: 64px;
	border-radius: 10px;
}
.regHead h2
{ 
	color: #f0f0f0 !important; 
	padding: 5px;
}

.form-check .form-check-input
{
	float: none !important;
}

/*end netquix*/

/* Avongla*/

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
  @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');
  
  /* #Primary
  ================================================== */
  
  body{
	  font-family: 'Poppins', sans-serif;
	  color: #212112;
	  width: 100%;
  
  }
  ::selection {
	  color: #fff;
	  background-color: #00319a;
  }
  ::-moz-selection {
	  color: #fff;
	  background-color: #00319a;
  }
  
  
  /* #Navigation
  ================================================== */
  
  .start-header {
	  opacity: 1;
	  transform: translateY(0);
	  padding: 10px 0;
	  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	  -webkit-transition : all 0.3s ease-out;
	  transition : all 0.3s ease-out;
  }
  .start-header.scroll-on {
	  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	  padding: 10px 0;
	  -webkit-transition : all 0.3s ease-out;
	  transition : all 0.3s ease-out;
  }
  .start-header.scroll-on .navbar-brand img{
	  height: 24px;
	  -webkit-transition : all 0.3s ease-out;
	  transition : all 0.3s ease-out;
  }
  .navigation-wrap{
	  position: sticky;
	  width: 100%;
	  top: 0;
	  left: 0;
	  z-index: 1000;
	  -webkit-transition : all 0.3s ease-out;
	  transition : all 0.3s ease-out;
  }
  .navbar{
	  padding: 0;
  }
  .navbar-brand img{
	   
	  width: auto;
	  display: block;
  
  }
  .navbar-toggler {
	  float: right;
	  border: none;
	  padding-right: 0;
  }
  .navbar-toggler:active,
  .navbar-toggler:focus {
	  outline: none;
  }
  .navbar-light .navbar-toggler-icon {
	  width: 24px;
	  height: 17px;
	  background-image: none;
	  position: relative;
	  border-bottom: 1px solid #000;
	  transition: all 300ms linear;
  }
  .navbar-light .navbar-toggler-icon:after, 
  .navbar-light .navbar-toggler-icon:before{
	  width: 24px;
	  position: absolute;
	  height: 1px;
	  background-color: #000;
	  top: 0;
	  left: 0;
	  content: '';
	  z-index: 2;
	  transition: all 300ms linear;
  }
  .navbar-light .navbar-toggler-icon:after{
	  top: 8px;
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	  transform: rotate(45deg);
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	  transform: translateY(8px) rotate(-45deg);
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	  border-color: transparent;
  }
  .nav-link{
	  color: #212121 !important;
	  font-weight: 500;
	  transition: all 200ms linear;
  }
  .nav-item:hover .nav-link{
	  color: #8167a9 !important;
  }
  .nav-item.active .nav-link{
	  color: #777 !important;
  }
  .nav-link {
	  position: relative;
	  padding: 5px 0 !important;
	  display: inline-block;
  }
  .nav-item:after{
	  position: absolute;
	  bottom: -5px;
	  left: 0;
	  width: 100%;
	  height: 2px;
	  content: '';
	  background-color: #8167a9;
	  opacity: 0;
	  transition: all 200ms linear;
  }
  .nav-item:hover:after{
	  bottom: 0;
	  opacity: 1;
  }
  .nav-item.active:hover:after{
	  opacity: 0;
  }
  .nav-item{
	  position: relative;
	  transition: all 200ms linear;
  }
  
  /* #Primary style
  ================================================== */
  
  .bg-light {
	  background-color: #fff !important;
	  transition: all 200ms linear;
  }
  .section {
	  
	  width: 100%;
	  display: block;
  }
  .full-height {
	  height: 100vh;
  }
  .over-hide {
	  overflow: hidden;
  }
  .absolute-center {
	  position: absolute;
	  top: 50%;
	  left: 0;
	  width: 100%;
	margin-top: 40px;
	  transform: translateY(-50%);
	  z-index: 20;
  }
  h1{
	  font-size: 48px;
	  line-height: 1.2;
	  font-weight: 700;
	  color: #212112;
	  text-align: center;
  }
  p{
	  margin: 0;
	  opacity: 1;
  }
  
  body.hero-anime #switch{
	  opacity: 0;
	  transform: translateY(40px);
	  transition-delay: 1900ms;
  }
  .nav-item .dropdown-menu {
	  transform: translate3d(0, 10px, 0);
	  visibility: hidden;
	  opacity: 0;
	  max-height: 0;
	  display: block;
	  padding: 0;
	  margin: 0;
	  transition: all 200ms linear;
  }
  .nav-item.show .dropdown-menu {
	  opacity: 1;
	  visibility: visible;
	  max-height: 999px;
	  transform: translate3d(0, 0px, 0);
  }
  .dropdown-menu {
	  padding: 10px!important;
	  margin: 0;
	  font-size: 13px;
	  letter-spacing: 1px;
	  color: #212121;
	  background-color: #fcfaff;
	  border: none;
	  border-radius: 3px;
	  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	  transition: all 200ms linear;
  }
  .dropdown-toggle::after {
	  display: none;
  }
  
  .dropdown-item {
	  padding: 3px 15px;
	  color: #212121;
	  border-radius: 2px;
	  transition: all 200ms linear;
  }
  .dropdown-item:hover, 
  .dropdown-item:focus {
	  color: #fff;
	  background-color: rgba(129,103,169,.6);
  }
  
  body.dark{
	  color: #fff;
	  background-color: #1f2029;
  }
  /* #Media
  ================================================== */
  
  @media (max-width: 767px) { 
	  h1{
		  font-size: 38px;
	  }
	  .nav-item:after{
		  display: none;
	  }
	  .nav-item::before {
		  position: absolute;
		  display: block;
		  top: 15px;
		  left: 0;
		  width: 11px;
		  height: 1px;
		  content: "";
		  border: none;
		  background-color: #000;
		  vertical-align: 0;
	  }
	  .dropdown-toggle::after {
		  position: absolute;
		  display: block;
		  top: 10px;
		  left: -23px;
		  width: 1px;
		  height: 11px;
		  content: "";
		  border: none;
		  background-color: #000;
		  vertical-align: 0;
		  transition: all 200ms linear;
	  }
	  .dropdown-toggle[aria-expanded="true"]::after{
		  transform: rotate(90deg);
		  opacity: 0;
	  }
  
  }
  
  div#navbarSupportedContent ul li {
	  margin: 0 15px;
	  align-self: center;
  }
  div#navbarSupportedContent ul li button {
	  padding: 5px 25px;
	  background-color: #113293;
	  color: #fff;
	  border-radius: 50px;
	  border: none;
  }
  /* .row {
	  margin: 0 auto;
	  width: 100%;
  } */
  #banner{
	  /*margin-top: 1110px;*/
	  position: absolute;
	  /* ../assests/images/banner.png */ 
	background-repeat: no-repeat;
	background-size: cover;
	
	  background-repeat: no-repeat;
	  background-position: center center;
	  width: 100%;
  
		text-align: left; 
	  position: absolute;
	  top: 0px;
	  bottom: 25px;
	  left: 100px;
	  /* right: 0; */
	  margin: auto;
	  z-index: 3; 
  }
  #banner{
	 display: flex;
  }
  
  /* .login_form{
	  align-self: center;
  } */
  
	  .login_input {
	  padding: 20px;
	  border-radius: 20px;
	  background: #ededed !important;
  }
  .login_input h2{
	  text-transform: uppercase;
  }
  .sign_btn.text-center button {
	  width: 100%;
	  background: #002392;
	  margin-top: 20px;
  }
  .container-fluid{
	  padding: 0em 2em;
  }
  .about_info{
	  padding: 3em 4em;
	  background: #fbf9f7;
  }
  .about_info h3{
	  color: #002392;
	  font-size: 50px;
	  font-weight: 700;
  }
  .about_info p{
	  text-align: left;
  }
  .works{
	  padding: 3em 4em;
	  background: #f0f0f0;
  }
  .works h3{
	  color: #dd3d39;
	  font-weight: 600;
	  padding: 20px 0px;
  }
  .works h4{
	  color: #0e3496;
	  font-weight: 600;
	  padding: 10px 0px;
  }
  .works .col-md-4{
	  margin-bottom: 50px;
  }
  .findgame_info{
	  padding: 3em 4em;
	  background: #fff;
  }
  .howfind img{
	  width: 85%;
  }
  .gameinfo{
	  align-self: center;
  }
  .gameinfo h4{
  color: #0e3496;
  padding: 20px 0px;
  font-weight: 600;
  }
  .gameinfo p{
	  margin-bottom: 25px;
  }
  .coursebtn .col-md-6{
	  padding-left: 0px;
  }
  .coursebtn a{
	  background-color: #dd3d39;
	  border-radius:5px ;
	  padding: 15px 20px;
	  color: #fff;
	  font-weight: 600;
	  text-decoration: none;
  }
  .coursebtn a img{
	  width: 25px;
	  height: 25px;
	  margin-right: 20px;
  }
  .row.coursebtn h6 {
	  padding: 20px 0;
	  color: #002290;
	  font-weight: 700;
  } 
  
  /* 	Dashboard */
  
  .dropdown-menu {
	  left: 0px;
  }
  .dropdown-menu.show {
	  left: 0px;
  }
  #navbarDropdownMenuLink{
	  color: #002290 !important;
  }
  /* .dashboard_info{
	  padding: 4em 5em;
  } */
  .sidebar a {
	text-decoration: none;
	font-size: 20px;
	color: #818181;
	display: flex;
  }
  /* .upload_img img {
	  width: 119px;
	  height: 119px;
	  padding: 10px;
	  border-radius: 10px;
	  border: 2px solid #000;
	  margin-left: 50px;
  } */
  .sidebar a {
	  margin-bottom: 20px;
  }
  .sidebar a .fa {
	  border: 2px solid #000;
	  padding: 14px;
	  border-radius: 50px;
	  font-size: 20px;
	  color: #00319b;
	  margin-right: 10px;
	  width: 50px;
	  height: 50px;
  }
  
  ul.nav.nav-tabs {
	  background: #113293;
	  padding: 15px;
	  border-radius: 10px;
  }
  ul.nav.nav-tabs li a label{
	  color: #fff !important;
	  padding:15px 20px !important;
  }
  ul.nav.nav-tabs p {
	  color: #f5b551 !important;
	  margin-top: 15px;
	  float: right;
	  margin-left: 22%;
	  font-weight: 700;
  }
	  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	  color: #495057;
	  background-color: #113293 !important;
	  border: none !important;
	  padding: 15px;
	  height: 50px;
  }
  .nav-tabs .nav-item:hover .nav-link{
	  border: none !important;
  }
  
  .nav-tabs.nav-item:hover .nav-link{
	  color: #fff !important;
  }
  .tab-content .col-md-3{
	  padding: 10px;
  }
  .tab-content .col-md-3 img{
	  width: 100%;
	  margin-top: 20px;
	  height: 150px;
  }
  /* .tab-content {
	  background: #eee;
	  padding: 10px;
  } */
  
  

  p.small {
	  text-align: right;
  }
  /* span.register {
	  color: #007bff;
	  margin-left: 10px;
	  cursor: pointer;
  } */
  /* .login_form input {
	  display: block;
	  width: 100%;
	  height: calc(1.5em + 0.75rem + 2px);
	  font-weight: 400;
	  line-height: 1.5;
	  color: #495057;
	  background-color: #fff;
	  background-clip: padding-box;
	  border: 1px solid #ced4da;
	  border-radius: 0.25rem;
	  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  } */
  .sign_btn.text-center button {
	  width: 100%;
	  background: #002392;
	  margin-top: 20px;
  }
  #form-switch {
	display:none
  }
  #register-form {
	display:none
  }
  #form-switch:checked~#register-form {
	display:block
  }
  #form-switch:checked~#login-form {
	display:none
  }
  .ul .navbar-nav .m1-auto
  {
	margin-left: auto!important;
  }  
  .regHead
  {
	  background-color:#002d97;
	  color: #f0f0f0 !important;
	  padding: 64px;
	  border-radius: 10px;
  }
  .regHead h2
  { 
	  color: #f0f0f0 !important; 
	  padding: 5px;
  }
   
  
	body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .et_pb_section_video_bg {
	  visibility: visible;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  overflow: hidden;
	  display: block;
	  pointer-events: none;
	  -webkit-transition: display .3s;
	  transition: display .3s;
  }
  .loginbutton
  {font-size: 25px; 
	  padding: 5px 25px;
	  background-color: #b5cb8b !important;
	  color: #fff !important;
	  border-radius: 50px;
	  border: none;
  }
  .modal-dialog
  {
  max-width: 465px;
  margin: 4.75rem auto;
  }
  .traineebook
  {
 
	font-weight: bold;
  }
  .traineebook a{
	color:#5c5c5e !important;
  }
  .checkschedulecaption
  {
	color:#000; 
	font-weight: bold;
  }
  .display-4 {
	color: #fff;
  }
.rc-time-picker
{
	width:150px !important;
	padding-left: 10px;
}
.anticon svg {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
}
.customCol
{
	flex: 6;
}

.customTimeCol
{
	flex: 23;
}

.rc-time-picker-panel-select
{
	width:45px;

}

.rc-time-picker-panel 
{
	width: 140px;
}
.rc-time-picker

{
	width: 200px;
}
.section-title {
	background: #113293;
	padding: 15px;
	border-radius: 10px;
	color: white !important;
}
@media screen and (max-width:768px) {
	.ant-popover{
		width: 100%;
	}
	.ant-popover.menu-popover{
		position: relative;
		z-index: 2;
		left: 0 !important;
	}
}