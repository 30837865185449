.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}
video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
}
.bg-video-wrap h1 {
  text-align: left;
  color: #fff;
  position: absolute;
  top: 0px;
  bottom: 25px;
  left: 100px;
  /* right: 0; */
  margin: auto;
  z-index: 2;
  max-width: 400px;
  width: 100%;
  height: 50px;
}

.Sample {
  text-align: left;
  color: #fff;
  position: absolute;
  top: 240px;
  bottom: 25px;
  left: 100px;
  /* right: 0; */
  margin: auto;
  z-index: 3;
  max-width: 400px;
  width: 100%;
  height: 50px;
}
#banner{
    background-image: url();
    background-repeat: no-repeat;
    background-size: cover;
      height: 660px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
  }
  #banner{
     display: flex;
  }

  .about_info {
	background: #fff;
}
.about_info img{
	width:100%;
}
@media screen and (min-width:600px) and (max-width:767px) {
  .ant-layout-content {
    width: 100% !important;
    top: 70vw !important;
  }
  .ant-layout-content.content-layout {
    top: 15vw !important;
  }
}
@media screen and (max-width:768px) {
  .bg-video-wrap h1{
    text-align: center;
    left: 0;
    height: auto;
    top: 50vw;
    max-width: 100%;
  }
  .Sample{
    text-align: center;
    left: 0;
    height: auto;
    top: 60vw;
    z-index: 0;
    max-width: 100%;
  }
  .about_info{
    padding:0 !important;
  }
  footer ul li a, footer ul li a:hover{
    color: #000 !important;
  }
}
@media screen and (max-width:767px) {
  .bg-video-wrap h1{
    text-align: center;
    left: 0;
    height: auto;
    top: 50vw;
    max-width: 100%;
  }
  .Sample{
    text-align: center;
    left: 0;
    height: auto;
    top: 80vw;
    z-index: 0;
    max-width: 100%;
  }
  .about_info{
    padding:0 !important;
  }
  footer ul li a, footer ul li a:hover{
    color: #000 !important;
  }
}



