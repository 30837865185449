.signup_container {
  height: 410px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightpink;
  border-radius:10px;
}

@media screen and (max-width: 768px) {
  .signup_container  {
    width: 300px;
  }
}
.register-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.register-form-container h1 {
    font-size: 32px;
    font-family: "Sofia", sans-serif;
}
.input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 16px;
  font-weight:700;
}

.sign-up-error-message {
  width: 250px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}


.sign-up-button{
  padding: 12px 0;
  background-color: #edf5f3;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border:none;
  margin-top: 10px;
  display:flex;
  justify-content:center;
  align-items:center;
}

@media screen and (max-width: 768px) {
  .register-form-container h1 {
    font-size: 15px;
  }
  .input {
    width: 260px;
    padding: 10px;
  }
  .sign-up-button{
    padding: 10px 0;
    font-size: 12px;
  }
}