.updateProfile{
    /* float: right; */
    /* bottom: 10px; */
}
.manage-password {
    width: 500px;
    border-radius: 6px;
    background-color: #ededed;
}
.manage-password.ant-input {
    border-radius: 4px !important;
}.rowstyle img {
    width: 120px;
    height: 120px; 
    border-radius: 10px;
    border: 2px solid #000;  
  }

@media screen and (max-width:768px) {
  .manage-password{
    width: 100%;
  }
  .rowstyle img {
    margin-bottom: 3vw; 
  }

}