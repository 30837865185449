.upload-img img {
  width: 120px;
  height: 120px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #000; 
  margin: 20px;
}
.main-layout {
  height: 100vh;
  .ant-layout-sider {
    padding-top: 125px;
    max-width: 220px !important;
    width: 220px !important; 
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      width: 100% !important; 
      min-width: 100% !important;
      position: absolute !important;
      padding-top: 0;
      display: none;
    }
    .ant-menu-item {
      padding-left: 16px !important;
      cursor: pointer !important;
    }
    .ant-menu-vertical {
      border: none !important;
    }
  }
  .sidenav-menu {
    
    font-size: 20px;
    .ant-menu-item-selected {
      background-color: #113293 !important;
      color: white !important;
    }
  }
}
