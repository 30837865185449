.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
  min-width: 900px;
}
.header2-text
{
  color: #113293;
}
#RBS-Scheduler-root
{
  width: 700px !important;
  overflow-x: scroll;
}
.ant-row-flex-space-between {
  justify-content: flex-start !important;
  }
  .header1-text {
    color: #ec1818;
    font-size: 17px;
    font-weight: 500;
} 
.help-text{
  color: #ec1818;
  font-size: 17px;
  font-weight: 500;
  margin-left: 2px;
}
.resource-table 
{
  width: 166px !important;
}
.resource-view
{
  width: 166px !important;
}
.slot-cell img {
  width: 119px;
  height: 119px;
  padding: 10px;
  border-radius: 30px;
  border: 2px solid #000; 
}
.slot-text
{
  padding-left: 20px;
}

.round-all  
{
    
  padding-right: 0px !important;
}
