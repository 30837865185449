.signup-modal {
  max-width: 420px;
  @media screen and (max-width:768px) {
    max-width: 100%;;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .signup_form {
    text-align: left;
    //   background: #eee;
    max-width: 400px;
    margin: 0px auto;
    padding-top: 10px;
    border-radius: 20px;
    //   background: #ededed !important;
    .ant-form-item-control {
      padding: 0 !important;
    }
    .forgot-password-btn {
      margin-left: auto;
      display: flex;
    }
    .ant-form-item {
      &.password-input {
        margin-bottom: 5px !important;
      }
    } .signup_form button {
      padding: 5px;
      }
  }
  .signup_form h2 {
    // color: #1a73e8;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .signup_form button {
  padding: 5px;
  }
  .ant-form-item 
  {
    margin-bottom: 10px !important;
  }
}
