.main-layout {
  width: 100vw;
  height: 100vh;
  @media screen and (max-width: 768px){
    background-color: #fff;
  }
  .ant-layout-header {
    .header-container {
      display: flex;
      align-items: center;
      .toggle-btn {
        margin-left: auto;
        cursor: pointer;
      }
    }
    padding: 0 40px 0 20px !important;
    height: 100px !important;
    z-index: 99 !important;
    .ant-menu {
      margin-left: auto;
      &.ant-menu-horizontal {
        border-bottom: none !important;
      }
    }
  }
}

.menu-popover {
  .ant-popover-arrow {
    display: none;
  }
  .ant-menu-vertical {
    border: none !important;
  }
}
.content-layout {
  margin-left: 200px;
  height: 100vh;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.optionListContainer{
  z-index: 9 !important;
}
// .ant-layout-content.content-layout{
//   @media screen and (max-width:768px) {
//     top: 24vw;
//   }
// }
.ant-layout-content{
  @media screen and (max-width:768px) {
    position: absolute;
    width: 98% !important;
    top: 57vw;
  }
  @media screen and (max-width:767px) {
    position: absolute;
    width: 98% !important;
    top: 16vw;
  }
}
// .ant-layout.main-layout{
//   @media screen and (max-width:768px) {
//     display: block !important;
//   }
// }

.scheduled-training-sessions{
  @media screen and (max-width:768px) {
      width: 100% !important;
    }
}

.confirm-box{
  @media screen and (max-width:768px) {
    left: 15vw !important;
    top: 60vw !important;
  }
}

// .ant-menu-item{
//   @media screen and (max-width:768px) {
//     width: 100vw;
//     display: block;
//   }
// }

.ant-menu-horizontal{
  @media screen and (max-width:768px) {
    margin-bottom: 15px;
  }
}