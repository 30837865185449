.forget-password-btn {
    color: white;
}
.otp-title {
    font-weight: 500;
}
.opt-submit-form {
    margin-top: 10px;
    margin-bottom: 20px;
}

.forgot-password-modal {
    .ant-modal-content {
      border-radius: 20px;
    }
    .forgot-password-form {
      text-align: left;
      //   background: #eee;
      max-width: 200px;
      margin: 0px auto;
      padding-top: 10px;
      border-radius: 20px;
      //   background: #ededed !important;
      .ant-form-item-control {
        padding: 0 !important;
      }
      .forgot-password-btn {
        margin-left: auto;
        display: flex;
      }
      .ant-form-item {
        &.password-input {
          margin-bottom: 5px !important;
        }
      }
    }
    .forgot-password-form h2 {
      font-size: 25px;
      margin-bottom: 20px;
    }
  }
  